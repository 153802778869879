<template>
    <div class="mt-5 px-xl-5">
        <h6 class="page-title pb-2 text-uppercase">
            <b-button class="main-btn rounded me-2" @click="goOfficials">
                <b-icon icon="arrow-left" />
            </b-button>
            {{ $t("official.title") }}
        </h6>
        <b-row class="mt-3">
            <b-col>
                <b-card border-variant="light" class="shadow-sm" header-class="border-0">
                    <template #header>
                        <h6 class="mb-0 details-title-value">
                            {{ $t("official.sub-title") }}
                        </h6>
                    </template>
                    <b-card-text>
                        <b-row v-if="isLoading" class="justify-content-center my-2">
                            <b-spinner variant="primary" />
                            <h6 class="page-title text-center mt-2">
                                {{ $t("official.loading") }}
                            </h6>
                        </b-row>
                        <b-row v-if="!isLoading">
                            <b-col lg="6">
                                <OfficialProperty :label="$t('general.name')" :value="official.name" />
                                <OfficialProperty :label="$t('general.email')" :value="official.email" />
                                <OfficialProperty :label="$t('general.phone')" :value="official.phone" />
                                <OfficialProperty :label="$t('general.identification')"
                                    :value="official.identification" />
                                <OfficialProperty :label="$t('general.creation-date')" :value="official.creationDate" />
                                <OfficialProperty :label="$t('general.roles')" :value="roles" />
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
        <OfficialPermissions v-if="!isLoading" :official="official" />
    </div>
</template>

<script>
import moment from "moment";
import { RepositoryFactory } from "@/services/api";
import { mapActions } from "vuex";

import OfficialProperty from "./OfficialProperty.vue";
import OfficialPermissions from "./OfficialPermissions.vue";

import { parseRoles } from "@/utils/parse.js"

export default {
    name: "official",
    components: {
        OfficialProperty,
        OfficialPermissions,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isLoading: true,
            official: null,
            roles: ''
        };
    },
    mounted() {
        this.retrieveSuperintendece();
    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        async retrieveSuperintendece() {
            this.isLoading = true;
            try {
                let response = await RepositoryFactory.official.get({
                    id: this.id,
                });

                let official = response.data;

                official.creationDate = this.parseAndConvertDate(
                    official.creationDate
                );

                this.roles = parseRoles(official.roles)
                this.official = official;
            } catch {
                this.showError();
            }
            this.isLoading = false;
        },
        showError() {
            this.setModalInfo({
                title: this.$t(`official.error.title`),
                message: this.$t(`official.error.message`),
                subMessage: this.$t(`official.error.subMessage`),
            });
            this.openModal();
        },
        parseAndConvertDate(date) {
            return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
        },
        goOfficials() {
            this.$router.push({
                name: "officials",
            });
        },
    },
};
</script>